export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21')
];

export const server_loads = [3,2];

export const dictionary = {
		"/(auth)": [~15,[3]],
		"/(app)/dashboard": [~4,[2]],
		"/(app)/dashboard/company-profile": [~5,[2]],
		"/(app)/dashboard/contacts": [~6,[2]],
		"/(app)/dashboard/customers": [~7,[2]],
		"/(app)/dashboard/inspections": [~8,[2]],
		"/(app)/dashboard/inspections/create-inspection": [~10,[2]],
		"/(app)/dashboard/inspections/create-report": [~11,[2]],
		"/(app)/dashboard/inspections/view-report": [~12,[2]],
		"/(app)/dashboard/inspections/[inspection]": [~9,[2]],
		"/(app)/dashboard/user-profile": [~13,[2]],
		"/(app)/dashboard/users": [~14,[2]],
		"/(auth)/forgot-password": [~16,[3]],
		"/logout": [21],
		"/(auth)/reset-password": [~17,[3]],
		"/(auth)/signup/company-registration": [~18,[3]],
		"/(auth)/signup/payment-picker": [~19,[3]],
		"/(auth)/signup/thanks": [20,[3]]
	};

export const hooks = {
	handleError: (({ error }) => { console.error(error) }),
};

export { default as root } from '../root.svelte';